import React from 'react'
import { Link, graphql } from 'gatsby'
import axios from "axios";

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'

class InstagramFeed extends React.Component {
  state = {
    images: null
  };

  componentDidMount() {
    axios.get("https://graph.instagram.com/17841407303136419/media?fields=id,username,media_url,thumbnail_url,%20permalink&access_token=IGQVJXbldtVENGRUhqZAV9yVWRRNUpmLW1ONjU4U2tjUWtZAQXIwU240X19xeTlkcnFpUmpMSjlDY2JvME84VFFBSHlTY19NeFJMZAGpOVkhqWDJ4dnlyY2ZAqNkpfUjd4aEhiekF5T2ZAkYU1nU1lNRmlxagZDZD")
      .then(response => {
        console.log("resp", response);

        this.setState({
          ...this.state,
          images: response.data.data.slice(0, 6)
        });
      })
      .catch(e => console.log("Instafeed error", e));
  }

  render() {
    const { images } = this.state;
    return (
      <>
        {images &&
          <div className="component-instagrid">
            <div className="columns is-mobile">
              <div className="column is-narrow">
                <h2>Instagram</h2>
              </div>

              <div className="column handle">
                <a href="https://instagram.com/eque_mag" target="_blank">@eque_mag</a>
              </div>
            </div>

            <div className="columns is-multiline is-mobile">
              {images.map(img => (
                <div className="column is-4-mobile is-2-tablet is-2-desktop is-2-widescreen is-2-fullhd">
                  <a href={img.permalink} target="_blank">
                    <img src={img.media_url} />
                  </a>
                </div>
              ))}
            </div>
          </div>
        }
      </>
    )
  }
}

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMdx.edges;
    const featuredPost = posts.length > 0 ? posts[0] : null;
    const recentPosts = data.recentPosts.edges.filter(post => {
      return post.node.fields.slug !== featuredPost.node.fields.slug;
    });

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`EQUE`, `EQUE Mag`, `New Music`, `Playlists` ]}
          image="https://equemag.com/img/eque-share-main.jpg"
        />

        <div class="pages-index">
          {featuredPost &&
            <Link to={featuredPost.node.fields.slug}>
              <div className="container">
                <div className="columns post-single ui-grid home-featured">
                  <div className="column is-6-tablet is-7-desktop is-7-widescreen is-7-fullhd">
                    <img src={featuredPost.node.frontmatter.featuredImage} className="post-cover" />
                  </div>

                  <div className="column is-1">
                  </div>

                  <div className="column is-5-tablet is-4-desktop is-4-widescreen is-4-fullhd">
                    <h2><span>{featuredPost.node.frontmatter.title}</span></h2>
                    <p>{featuredPost.node.frontmatter.description}</p>
                    <p className="read-more">Discover with {featuredPost.node.frontmatter.curatedByName}  →</p>

                    
                    </div>
                  </div>
                </div>
            </Link>
          }

          <div className="container">
            <div className="columns post-single ui-grid home-about">
              <div className="column is-10">
                <p class="title">
                   Welcome to EQUE
                </p>
                <h2>
                EQUE is a place to discover new music through familiar voices.
                </h2>

                <a class="text-link" href="/about">
                  Read our story
                </a>
              </div>
            </div>
          </div>

          
            <div className="container">
            <div className="post-grid">
              <div className="columns is-multiline">
                {recentPosts.slice(0,8).map(({ node }) => (
                  <div className="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen is-6-fullhd">
                    <Link to={node.fields.slug}>
                      <img src={node.frontmatter.featuredImage} />
                      <p className="title"><span>{node.frontmatter.title}</span></p>
                      {node.frontmatter.curatedByName &&
                        <p className="curatedBy">Curated by {node.frontmatter.curatedByName}</p>
                      }
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          <div className="container">
            {/* <div className="columns ui-grid">
              <div className="column is-8"> */}
                <InstagramFeed />
              {/* </div>
            </div> */}
          </div>
        </div>

        
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {featuredPost: { eq: true }}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredPost
            featuredImage
            curatedByName
            curatedByImage
          }
        }
      }
    }
    recentPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredPost
            featuredImage
            curatedByName
          }
        }
      }
    }
  }
`
